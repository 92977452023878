<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'升降机生产商管理'" :line="true" />
            <CommonHeaders
                :formConfig="formConfig"
                :formModel="formModel"
                :buttons="buttons"
                @button-click="handleButtonClick"
            />
        </div>

        <div class="commonControl-body">
            <common-table
                :tableData="tableData"
                :tableButton="tableButton"
                :tableLabel="tableLabel"
                @edit="handleEdit"
            />
            <div class="layoutBorder">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[5, 10, 20, 40]"
                    :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper"
                />
            </div>
        </div>

        <el-dialog title="编辑生产商" :visible.sync="dialogFormVisibleEdit">
            <el-form :model="formEdit">
                <el-form-item label="生产商id" label-width="120px">
                    <el-input
                        v-model="formEdit.id"
                        autocomplete="off"
                        :disabled="true"
                    />
                </el-form-item>
                <el-form-item label="生产商名称" label-width="120px">
                    <el-input
                        v-model="formEdit.name"
                        autocomplete="off"
                    />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleEdit = false"
                    >取消</el-button
                >
                <el-button type="primary" @click="updateProducer"
                    >确定</el-button
                >
            </div>
        </el-dialog>

        <el-dialog title="新增生产商" :visible.sync="dialogFormVisibleAdd">
            <el-form :model="formAdd">
                <el-form-item label="生产商名称" label-width="120px">
                    <el-input
                        v-model="formAdd.producer_name"
                        autocomplete="off"
                    />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleAdd = false"
                    >取消</el-button
                >
                <el-button type="primary" @click="createProducer"
                    >确定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    lifter_manufacturer_manage,
    lifter_manufacturer_create,
    lifter_manufacturer_update,
    lifter_manufacturer_delete
} from '@/api/manage/operation/lifter_manage/lifter_manage.js';
import CommonTable from 'frontelementpackage/src/CommonTableButtonFixed.vue';
import CommonHeaders from '@/components/shibo_Common/CommonHeaders.vue';

export default {
    name: 'start',
    data() {
        return {
            query: {
                querypage: 1,
                pagesize: 10,
                keyword: ''
            },
            currentPage: 1,
            pagesize: 10,
            tableData: [],
            dialogFormVisibleEdit: false,
            formEdit: {},
            dialogFormVisibleAdd: false,
            formAdd: {},
            tableLabel: [
                { prop: 'id', label: 'id', minWidth: '50%' },
                // { prop: 'production_code', label: '厂商编号', minWidth: '50%' },
                { prop: 'name', label: '生产商名称', minWidth: '50%' }
            ],
            tableButton: {
                width: 300,
                data: [
                    { name: '详情', type: 'primary', size: 'small' },
                    { name: '编辑', type: 'info', size: 'small' },
                    { name: '删除', type: 'danger', size: 'small' }
                ]
            },
            formConfig: [],
            formModel: {},
            buttons: [
                { label: '新增升降机生产商', type: 'primary', action: 'create' }
            ]
        };
    },
    components: {
        CommonTable,
        CommonHeaders
    },
    created() {
        this.getProducers();
    },
    methods: {
        handleEdit(row, action) {
            if (action === '编辑') this.editProducer(row);
            if (action === '删除') this.deleteProducer(row);
            if (action === '详情') this.toProducer(row);
            
        },
        toProducer(row){
            row;
            this.$router.push({name:'lifter_Manufacturer_Product',query:{id:row.id}})
        },
        editProducer(row) {
            this.formEdit = { id: row.id, ...row };
            console.log(this.formEdit,'formEdit');
            this.dialogFormVisibleEdit = true;
        },
        deleteProducer(row) {
            this.$confirm('是否确定删除', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '删除',
                cancelButtonText: '放弃删除'
            })
                .then(() => {
                    lifter_manufacturer_delete({ manufacturer_id: row.id }).then(
                        res => {
                            if (res.code === 200) {
                                this.$message.success('成功删除');
                                this.getProducers();
                            } else {
                                this.$message.info(res.msg);
                            }
                        }
                    );
                })
                .catch(action => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '放弃删除' : '停留在当前'
                    });
                });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.query.querypage = val;
            this.getProducers();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.query.pagesize = val;
            this.getProducers();
        },
        createProducer() {
            lifter_manufacturer_create(this.formAdd).then(res => {
                if (res.code === 200) {
                    this.$message.success('成功添加');
                    this.getProducers();
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleAdd = false;
        },
        updateProducer() {
            lifter_manufacturer_update(this.formEdit).then(res => {
                if (res.code === 200) {
                    this.$message.success('成功修改');
                    this.getProducers();
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleEdit = false;
        },
        getProducers() {
            lifter_manufacturer_manage(this.query).then(res => {
                if (res.code === 200) {
                    this.tableData = res.data;
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        handleButtonClick(button) {
            if (button.action === 'create') {
                this.dialogFormVisibleAdd = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
